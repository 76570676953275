import { Container, Text } from "./style"
import React from "react"

export const Tag = ({ text, dark }) => {
  return (
    <Container dark={dark}>
      <Text dark={dark}>{text}</Text>
    </Container>
  )
}
