import styled, { css } from "styled-components"

export const Container = styled.div`
  padding: 10px 15px;
  border-radius: 25px;
  border: double 1.5px transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(81.84deg, #f23fc5 -20.74%, #f67bb2 53.5%, #a994dc 130.36%);
  ${props =>
    props.dark &&
    css`
      background-image: linear-gradient(white, white),
        linear-gradient(90deg, #ff2393 0%, #352497 100%);
    `}
  background-origin: border-box;
  background-clip: padding-box, border-box;
  @media (max-width: 890px) {
    padding: 5px 12px;
  }
`

export const Text = styled.h5`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  background: -webkit-linear-gradient(
    0deg,
    #f23fc5 -20.74%,
    #f67bb2 53.5%,
    #a994dc 130.36%
  );
  ${props =>
    props.dark &&
    css`
      background: -webkit-linear-gradient(0deg, #ff2393 0%, #352497 100%);
    `}
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 890px) {
    font-size: 12px;
  }
`
