import React from "react"
import {
  Container,
  TextContainer,
  Tags,
  ImageContainer,
  Top,
  Bottom,
  Wrapper,
  Background,
  Subtitle,
} from "./style"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { Tag } from "../Tag"
import { ReadMore } from "../ReadMore"

export const SectionElement = ({ product, isSubpage }) => {
  const image = getImage(product.thumbnail)
  return (
    <Container>
      <ImageContainer>
        {product.category === "blog" ? (
          <Background>
            <StaticImage
              src="../../images/gradient-texture.png"
              alt="gradient"
              placeholder="blurred"
              style={{ height: "100%" }}
            />
          </Background>
        ) : null}
        <GatsbyImage image={image} alt={product.slug} />
      </ImageContainer>
      <TextContainer>
        <Top>
          {!isSubpage ? <Subtitle>PRODUCT</Subtitle> : null}
          <h2>{product.title}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: product.shortDescription.childMarkdownRemark.html,
            }}
          ></div>
        </Top>
        <Bottom>
          <Tags>
            {product.tags.map((tag, index) => (
              <Tag key={index} text={tag} />
            ))}
          </Tags>
          <Wrapper>
            <ReadMore
              desiredPath={"/" + product.category + "/" + product.slug}
            />
          </Wrapper>
        </Bottom>
      </TextContainer>
    </Container>
  )
}
