import styled from "styled-components"

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 45%);
  gap: 10%;
  padding: 0 25px;
  @media (max-width: 890px) {
    display: flex;
    gap: 26px;
    flex-direction: column;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
`

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Tags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 80%;
  @media (max-width: 890px) {
    max-width: 100%;
    justify-content: center;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: start;
`

export const Background = styled.div`
  max-width: 400px;
  max-height: 400px;
  border-radius: 25px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
`

export const Subtitle = styled.h4`
  font-family: "Fahkwang", sans-serif;
  font-weight: 500;
  font-size: 14px;
`

export const title = styled.h3`
  font-family: "Fahkwang", sans-serif;
  font-weight: 500;
  font-size: 24px;
`
