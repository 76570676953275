import styled from "styled-components"
import { Link } from "gatsby"

export const Container = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const Text = styled.span`
  vertical-align: middle;
  text-align: center;
  padding: 10px 10px 10px 0px;
  font-size: 16px;
  font-weight: 600;
  &:active {
    font-weight: 700;
    margin-left: -1px;
  }
  &:before {
    display: block;
    content: attr(text);
    font-weight: 700;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`

export const Image = styled.img`
  padding: 10px 0 10px 10px;
`
