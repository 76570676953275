import React from "react"
import { Container, Text, Image } from "./style"
import arrow from "../../images/read-more-arrow.svg"

export const ReadMore = ({ desiredPath }) => {
  return (
    <Container to={desiredPath}>
      <Text text="Read more">Read more</Text>
      <Image src={arrow} alt="arrow" />
    </Container>
  )
}
